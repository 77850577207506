exports.components = {
  "component---src-pages-401-js": () => import("./../../../src/pages/401.js" /* webpackChunkName: "component---src-pages-401-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-accept-tours-[token]-js": () => import("./../../../src/pages/accept-tours/[token].js" /* webpackChunkName: "component---src-pages-accept-tours-[token]-js" */),
  "component---src-pages-de-401-js": () => import("./../../../src/pages/de/401.js" /* webpackChunkName: "component---src-pages-de-401-js" */),
  "component---src-pages-de-404-js": () => import("./../../../src/pages/de/404.js" /* webpackChunkName: "component---src-pages-de-404-js" */),
  "component---src-pages-de-500-js": () => import("./../../../src/pages/de/500.js" /* webpackChunkName: "component---src-pages-de-500-js" */),
  "component---src-pages-de-bewerben-js": () => import("./../../../src/pages/de/bewerben.js" /* webpackChunkName: "component---src-pages-de-bewerben-js" */),
  "component---src-pages-de-co-2-js": () => import("./../../../src/pages/de/co2.js" /* webpackChunkName: "component---src-pages-de-co-2-js" */),
  "component---src-pages-de-coilrechner-js": () => import("./../../../src/pages/de/coilrechner.js" /* webpackChunkName: "component---src-pages-de-coilrechner-js" */),
  "component---src-pages-de-datenschutz-js": () => import("./../../../src/pages/de/datenschutz.js" /* webpackChunkName: "component---src-pages-de-datenschutz-js" */),
  "component---src-pages-de-digitalisierung-js": () => import("./../../../src/pages/de/digitalisierung.js" /* webpackChunkName: "component---src-pages-de-digitalisierung-js" */),
  "component---src-pages-de-galerie-js": () => import("./../../../src/pages/de/galerie.js" /* webpackChunkName: "component---src-pages-de-galerie-js" */),
  "component---src-pages-de-impressum-js": () => import("./../../../src/pages/de/impressum.js" /* webpackChunkName: "component---src-pages-de-impressum-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-kontakt-js": () => import("./../../../src/pages/de/kontakt.js" /* webpackChunkName: "component---src-pages-de-kontakt-js" */),
  "component---src-pages-de-kundenportal-js": () => import("./../../../src/pages/de/kundenportal.js" /* webpackChunkName: "component---src-pages-de-kundenportal-js" */),
  "component---src-pages-de-lieferung-abholung-anmelden-js": () => import("./../../../src/pages/de/lieferung-abholung-anmelden.js" /* webpackChunkName: "component---src-pages-de-lieferung-abholung-anmelden-js" */),
  "component---src-pages-de-logistik-js": () => import("./../../../src/pages/de/logistik.js" /* webpackChunkName: "component---src-pages-de-logistik-js" */),
  "component---src-pages-de-produkte-js": () => import("./../../../src/pages/de/produkte.js" /* webpackChunkName: "component---src-pages-de-produkte-js" */),
  "component---src-pages-de-qualitaet-js": () => import("./../../../src/pages/de/qualitaet.js" /* webpackChunkName: "component---src-pages-de-qualitaet-js" */),
  "component---src-pages-de-stellenangebote-js": () => import("./../../../src/pages/de/stellenangebote.js" /* webpackChunkName: "component---src-pages-de-stellenangebote-js" */),
  "component---src-pages-de-unternehmen-js": () => import("./../../../src/pages/de/unternehmen.js" /* webpackChunkName: "component---src-pages-de-unternehmen-js" */),
  "component---src-pages-en-401-js": () => import("./../../../src/pages/en/401.js" /* webpackChunkName: "component---src-pages-en-401-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-500-js": () => import("./../../../src/pages/en/500.js" /* webpackChunkName: "component---src-pages-en-500-js" */),
  "component---src-pages-en-apply-js": () => import("./../../../src/pages/en/apply.js" /* webpackChunkName: "component---src-pages-en-apply-js" */),
  "component---src-pages-en-co-2-js": () => import("./../../../src/pages/en/co2.js" /* webpackChunkName: "component---src-pages-en-co-2-js" */),
  "component---src-pages-en-coilcalculator-js": () => import("./../../../src/pages/en/coilcalculator.js" /* webpackChunkName: "component---src-pages-en-coilcalculator-js" */),
  "component---src-pages-en-company-js": () => import("./../../../src/pages/en/company.js" /* webpackChunkName: "component---src-pages-en-company-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-customers-portal-js": () => import("./../../../src/pages/en/customers-portal.js" /* webpackChunkName: "component---src-pages-en-customers-portal-js" */),
  "component---src-pages-en-dataprivacy-js": () => import("./../../../src/pages/en/dataprivacy.js" /* webpackChunkName: "component---src-pages-en-dataprivacy-js" */),
  "component---src-pages-en-delivery-pickup-register-js": () => import("./../../../src/pages/en/delivery-pickup-register.js" /* webpackChunkName: "component---src-pages-en-delivery-pickup-register-js" */),
  "component---src-pages-en-digitalization-js": () => import("./../../../src/pages/en/digitalization.js" /* webpackChunkName: "component---src-pages-en-digitalization-js" */),
  "component---src-pages-en-gallery-js": () => import("./../../../src/pages/en/gallery.js" /* webpackChunkName: "component---src-pages-en-gallery-js" */),
  "component---src-pages-en-imprint-js": () => import("./../../../src/pages/en/imprint.js" /* webpackChunkName: "component---src-pages-en-imprint-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-jobs-js": () => import("./../../../src/pages/en/jobs.js" /* webpackChunkName: "component---src-pages-en-jobs-js" */),
  "component---src-pages-en-logistic-js": () => import("./../../../src/pages/en/logistic.js" /* webpackChunkName: "component---src-pages-en-logistic-js" */),
  "component---src-pages-en-products-js": () => import("./../../../src/pages/en/products.js" /* webpackChunkName: "component---src-pages-en-products-js" */),
  "component---src-pages-en-quality-js": () => import("./../../../src/pages/en/quality.js" /* webpackChunkName: "component---src-pages-en-quality-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */)
}

